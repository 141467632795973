<template>
  <form
    class="contact-form"
    :class="{ tried }"
    id="contact-form"
    action="#contact-form"
    @submit="onSubmit"
  >
    <h4 class="title mb-5" :class="{ 'text-medium-shade': !!theme }">
      <i class="isologo mb-2"></i>
      {{ $lang("contact_form.title", true) }}
    </h4>

    <input
      type="text"
      :placeholder="`${$lang('contact_form.first_name', true)} *`"
      v-model="form.first_name"
      required
      name="your-name"
    />
    <input
      type="text"
      :placeholder="`${$lang('contact_form.last_name', true)} *`"
      v-model="form.last_name"
      required
      name="last-name"
    />
    <input
      type="text"
      :placeholder="`${$lang('contact_form.email', true)} *`"
      v-model="form.email"
      required
      name="your-email"
    />
    <input
      type="text"
      :placeholder="`${$lang('contact_form.phone', true)} *`"
      v-model="form.phone"
      required
      name="phone"
    />
    <Select
      v-model="form.country"
      :label="`${$lang('contact_form.country', true)} *`"
      :options="countries"
      name="country"
    />
    <Select
      v-model="form.nationality"
      :label="`${$lang('contact_form.nationality', true)} *`"
      :options="nationalities"
      name="nationality"
    />

    <div class="ml-4 mb-3">
      <b class="text-medium">{{ $lang("contact_form.campus", true) }}</b>
      <Radio
        label="Barcelona"
        inputValue="Barcelona"
        name="campus"
        v-model="form.campus"
      />
      <Radio
        label="Madrid"
        inputValue="Madrid"
        name="campus"
        v-model="form.campus"
      />
    </div>

    <textarea
      :placeholder="$lang('contact_form.message', true)"
      rows="4"
      v-model="form.message"
      name="message"
    />

    <Checkbox
      class="ml-4"
      :label="$lang('contact_form.terms', true)"
      v-model="form.terms"
      name="terms-accepted"
      :isInvalid="terms_invalid"
    />

    <button
      type="submit"
      @click="tried = true"
      class="btn btn-block btn-primary mt-5"
    >
      {{ $lang("contact_form.button", true) }}
    </button>
  </form>
</template>

<script>
// import { createPmlScript, removePmlScript } from "@/utils/pmlHelper.js";
import countries_es from "@/assets/json/countries-es.json";
import countries_en from "@/assets/json/countries-en.json";
import Checkbox from "@/components/Forms/Checkbox.vue";
import Radio from "@/components/Forms/Radio.vue";
import Select from "@/components/Forms/Select.vue";
import axios from 'axios';

export default {
  components: {
    Checkbox,
    Radio,
    Select,
  },
  props: {
    theme: {
      type: String,
      default: null,
    },
  },
  data: () => ({
    form: {
      first_name: null,
      last_name: null,
      email: null,
      phone: null,
      country: null,
      nationality: null,
      campus: "Barcelona",
      message: null,
      terms: false,
    },
    tried: false,
    terms_invalid: false,
    countries_en,
    countries_es,
  }),
  computed: {
    countries() {
      return (
        this.$route.params.lang === "en" ? this.countries_en : this.countries_es
      ).map((x) => ({
        value: x.name,
        label: x.name,
      }));
    },
    nationalities() {
      return (
        this.$route.params.lang === "en" ? this.countries_en : this.countries_es
      ).map((x) => ({
        value: x.nationality,
        label: x.nationality,
      }));
    },
  },
  // beforeUnmount() {
  //   removePmlScript();
  // },
  // mounted() {
  //   createPmlScript(this.$route.params.lang === "en" ? "english" : "spanish");
  // },
  methods: {
    onSubmit(e) {
      e.preventDefault();
      if (!this.isValid()) return;

      axios.get('https://api.pumpmylead.com/integrations/informs/v1/webhook', {
        params: {
          'your-name': this.form.first_name,
          'last-name': this.form.last_name,
          'your-email': this.form.email,
          'phone': this.form.phone,
          'country': this.form.country,
          'message': this.form.message,
          'nationality': this.form.nationality,
          'campus': this.form.campus,
          'token': this.$route.params.lang === "en" ? "91jiat2079ssire311955di469429c2eb" : "qrmmb32079vv4iol11955fp46942zfyxe"
        }
      })

      this.clearForm();

      this.formSuccessMessage();
    },
    isValid() {
      if (!this.form.terms) {
        this.$toast.success(this.$lang("contact_form.check_terms_error", true));
        this.terms_invalid = true;
        return false;
      }

      this.terms_invalid = false;
      return true;
    },
    clearForm() {
      this.tried = false;
      this.terms_invalid = false;
      this.form = {
        first_name: null,
        last_name: null,
        email: null,
        phone: null,
        country: null,
        nationality: null,
        campus: "Barcelona",
        message: null,
        terms: true,
      };
    },
    formSuccessMessage() {
      this.$toast.success(this.$lang("contact_form.success_msg", true));
    },
  },
};
</script>
