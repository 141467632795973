<template>
  <div id="contactUs">
    <section
      class="page-header"
      style="background-image: url('/imgs/contact_us.png')"
    >
      <div class="caption">
        <i class="isologo"></i>
        <h1>{{ $lang("title") }}</h1>
      </div>
    </section>

    <section class="bg-tertiary py-6 text-center text-white decoration-3">
      <div class="container">
        <p>{{ $lang("subtitle") }}</p>
      </div>
    </section>

    <section class="py-6">
      <div class="container">
        <div class="row">
          <div class="col-md-4 order-2 order-md-1 mt-4 mt-md-0">
            <h4 class="text-medium-shade mb-5">
              <i class="isologo mb-2"></i>
              {{ $lang("campus.title") }}
            </h4>

            <div class="campus-details mb-5">
              <h5 class="campus-title text-secondary">Barcelona Campus</h5>
              <p class="text-medium">
                Carrer de Guillem Tell, 27, <br />
                08006 Barcelona, España
              </p>
              <p class="mb-0">
                <i class="mr-2 fa-solid fa-phone text-secondary"></i>
                <a class="text-dark" target="_blank" href="tel:34935151174">
                  +34 93 515 11 74
                </a>
              </p>
              <p class="mb-0">
                <i class="mr-2 fa-brands fa-whatsapp text-secondary"></i>
                <a
                  class="text-dark"
                  target="_blank"
                  href="http://wa.me/34634131769"
                >
                  +34 63 413 17 69
                </a>
              </p>
              <p class="mb-0">
                <i class="mr-2 fa-regular fa-envelope text-secondary"></i>
                <a
                  class="text-dark"
                  target="_blank"
                  href="mailto:info@nlcollege.es"
                >
                  info@nlcollege.es
                </a>
              </p>
            </div>
            <div class="campus-details mb-5">
              <h5 class="campus-title text-tertiary">Madrid Campus</h5>
              <p class="text-medium">
                Plaza Puerta del Sol 11, <br />
                Segunda Planta, <br />
                28013 Madrid, España
              </p>
              <p class="mb-0">
                <i class="mr-2 fa-solid fa-phone text-tertiary"></i>
                <a class="text-dark" target="_blank" href="tel:34917376633">
                  +34 91 737 66 33
                </a>
              </p>
              <p class="mb-0">
                <i class="mr-2 fa-brands fa-whatsapp text-tertiary"></i>
                <a
                  class="text-dark"
                  target="_blank"
                  href="http://wa.me/34634131769"
                >
                  +34 63 413 17 69
                </a>
              </p>
              <p class="mb-0">
                <i class="mr-2 fa-regular fa-envelope text-tertiary"></i>
                <a
                  class="text-dark"
                  target="_blank"
                  href="mailto:info@nlcollege.es"
                >
                  info@nlcollege.es
                </a>
              </p>
            </div>
          </div>
          <div class="col-md-8 order-1 order-md-2">
            <ContactForm theme="light" />
          </div>
        </div>
      </div>
    </section>

    <section class="py-6 bg-secondary text-medium">
      <div class="container">
        <h4 class="text-center text-primary">{{ $lang("follow_us") }}</h4>

        <div class="d-flex flex-equal mt-5">
          <div class="grid-center">
            <a
              href="https://www.instagram.com/nlcollege.spain"
              target="_blank"
              class="d-flex align-items-center"
            >
              <button class="btn btn-rounded btn-primary">
                <i class="fa-brands fa-instagram"></i>
              </button>

              <strong class="text-white ml-3 flex-grow-1">
                nlcollege.spain
              </strong>
            </a>
          </div>
          <div class="grid-center">
            <a
              href="https://www.youtube.com/c/NLCollegeSpain"
              target="_blank"
              class="d-flex align-items-center"
            >
              <button class="btn btn-rounded btn-primary">
                <i class="fa-brands fa-youtube"></i>
              </button>

              <strong class="text-white ml-3 flex-grow-1">
                nlcollege.spain
              </strong>
            </a>
          </div>
          <div class="grid-center">
            <a
              href="https://www.linkedin.com/company/nlcollege-spain"
              target="_blank"
              class="d-flex align-items-center"
            >
              <button class="btn btn-rounded btn-primary">
                <i class="fa-brands fa-linkedin"></i>
              </button>

              <strong class="text-white ml-3 flex-grow-1">
                nlcollege.spain
              </strong>
            </a>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import ContactForm from "@/components/ContactForm";

export default {
  components: {
    ContactForm,
  },
};
</script>
